import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { LINE_ITEM_NIGHT, LINE_ITEM_DAY } from '../../util/types';
import config from '../../config';
import { reformatMoney } from '../../util/currency';
import css from './ListingPage.module.css';
import { AvatarSmall, NamedLink } from '../../components';

import { userDisplayNameAsString } from '../../util/data';
import VerifiedBadge from '../../components/VerifiedBadge/VerifiedBadge';

const SectionHeading = props => {
  const {
    priceTitle,
    formattedPrice,
    price,
    richTitle,
    category,
    author,
    isReferral,
    showContactUser,
    onContactUser,
    exclusiveListingInfo,
  } = props;

  const unitType = config.lineItemUnitType;
  const isNightly = unitType === LINE_ITEM_NIGHT;
  const isDaily = unitType === LINE_ITEM_DAY;
  const gold = exclusiveListingInfo?.level === 2;

  const reformattedPrice = reformatMoney(formattedPrice);

  const unitTranslationKey = isNightly
    ? 'ListingPage.perNight'
    : isDaily
    ? 'ListingPage.perDay'
    : 'ListingPage.perUnit';

  const authorDisplayName = userDisplayNameAsString(author, '');
  const authorShopName = author?.attributes.profile.publicData?.shopName;
  const authorShopNameExists = authorShopName && authorShopName !== '';

  return (
    <div className={css.sectionHeading}>
      <div className={css.desktopPriceContainer}>
        {!isReferral && (
          <div className={css.desktopPriceValue} title={priceTitle}>
            {price && price.amount == 0 ? 'Free' : reformattedPrice}
          </div>
        )}
        <div className={css.desktopPerUnit}>
          <FormattedMessage id={unitTranslationKey} />
        </div>
      </div>

      <div className={css.orderHeading}>
        <section className={css.pillsContainer}>
          <div className={css.categoryPill}> {category}</div>
          {exclusiveListingInfo?.isExclusive && (
            <VerifiedBadge gold={gold} className={css.goldPill} />
          )}
        </section>
        <h2 className={css.sectionHeadingTitle}>{richTitle}</h2>
        {!isReferral && (
          <div className={css.sectionHeadingPrice}>
            {price && price.amount == 0 ? 'Free' : reformattedPrice}
          </div>
        )}
        <div className={css.author}>
          <div className={css.sectionHeadingAuthorNameLink}>
            <AvatarSmall user={author} className={css.providerAvatar} />
            <NamedLink
              className={css.link}
              name="ProfilePage"
              params={{ id: author.id?.uuid ? author.id.uuid : '0' }}
            >
              <div className={css.sectionHeadingAuthorShopName}>
                {authorShopNameExists ? authorShopName : authorDisplayName}
              </div>
            </NamedLink>
          </div>
        </div>
      </div>

      {/* <div className={css.author}>
          {category} {" "}
          <FormattedMessage id="ListingPage.soldBy" values={{ name: authorLink }} />
          {showContactUser ? (
            <span className={css.contactWrapper}>
              <span className={css.separator}>•</span>
              <InlineTextButton
                rootClassName={css.contactLink}
                onClick={onContactUser}
                enforcePagePreloadFor="SignupPage"
              >
                <FormattedMessage id="ListingPage.contactUser" />
              </InlineTextButton>
            </span>
          ) : null}
        </div> */}
    </div>
  );
};

export default SectionHeading;
