import React from 'react';
import PropTypes from 'prop-types';
import css from './ListingPage.module.css';

function SectionShipping({ shippingCountries, productType = 'physical' }) {
  return (
    <>
      {productType !== 'digital' && productType !== 'service' ? (
        <>
          <h2 className={css.shippingTitle}>Shipping</h2>
          <p>
            <span>This item ships to: </span>

            {shippingCountries.some(x => x.country == 'RoW') ? (
              <span>everywhere</span>
            ) : (
              shippingCountries.map((country, index) => {
                return index == shippingCountries.length - 1 ? (
                  <span key={country.country}>{country.country}</span>
                ) : (
                  <span key={country.country}>{country.country}, </span>
                );
              })
            )}
          </p>
        </>
      ) : null}
    </>
  );
}

SectionShipping.propTypes = { shippingCountries: PropTypes.array, productType: PropTypes.string };

export default SectionShipping;
